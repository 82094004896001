import { useEffect, useRef } from 'react'
import { Title as MantineTitle, Text, Container, Button, Overlay, rem, Box } from '@mantine/core'
import cx from 'clsx'
import { Link } from 'react-router-dom'
import { ReactComponent as Logo } from '../../../static/econcretely-icon.svg'
import classes from './hero.module.scss'

export const Highlight = ({ children }) => (
  <span className={classes.highlight}>{children}</span>
)

const DefaultTitle = () => (
  <>Default Title</>
)

export function HomeHero(props) {
  const videoBgRef = useRef(null)
  const {
    Title = DefaultTitle,
    Subtitle = null,
    useVideoBackground = false,
    showFooterLinks = false,
  } = props

  useEffect(() => {
    const playVideo = async () => {
      if (!videoBgRef.current) return
      
      try {
        await videoBgRef.current.play()
      } catch (error) {
        console.error('Video playback failed:', error)
      }
    }

    if (useVideoBackground) {
      playVideo()
    }
  }, [useVideoBackground])

  return (
    <div className={classes.wrapper}>
      <Overlay color="#000" opacity={0.65} zIndex={1}>
        <Box mb="lg" style={{ display: 'flex', justifyContent: 'space-between', marginTop: '1rem', padding: '0 1rem', position: 'relative', zIndex: 2 }}>
          <Logo style={{ marginTop: '-8px' }} width={rem(45)} />
          <Button className={classes.heroLogin} component={Link} to="/login" size="sm" variant="outline">
            Customer Login
          </Button>
        </Box>
      </Overlay>

      {useVideoBackground && (
        <video 
          className={classes.concreteVideoBg} 
          autoPlay 
          playsInline
          muted
          loop 
          preload="auto"
          disablePictureInPicture 
          poster="https://econcrete.nyc3.cdn.digitaloceanspaces.com/concrete-stillframe.png" 
          ref={videoBgRef}
        >
          <source 
            src="https://econcrete.nyc3.cdn.digitaloceanspaces.com/concrete2.1.mp4" 
            type="video/mp4"
          />
        </video>
      )}

      <div className={classes.inner}>
        <MantineTitle className={classes.title} data-aos="fade-up">
          <Title />
        </MantineTitle>

        {Subtitle && (
          <Container size={640} data-aos="fade-up" data-aos-delay="200">
            <Text size="lg" className={classes.description}>
              <Subtitle />
            </Text>
          </Container>
        )}

        {showFooterLinks && (
          <div className={classes.controls}>
            <Button className={classes.control} variant="white" size="lg" data-aos="fade-up" data-aos-delay="600" component={Link} to="/register" mr="md">
              Get Started
            </Button>
            <Button className={cx(classes.control, classes.secondaryControl)} size="lg" data-aos="fade-up" data-aos-delay="600" component={Link} to="/contact">
              Schedule a demo
            </Button>
          </div>
        )}
      </div>
    </div>
  )
}
